<template>
  <div class="ceremonyWrap" v-loading="loadShow">
    <div class="mainCom w1200 clearfix">
      <div class="mainComL">
        <menuWX></menuWX>
      </div>
      <div class="mainComR">
        <div class="ceremonyBar mb20" :style="{ backgroundImage: 'url(' + ceremonyBg + ')' }">
          <div class="ceremonyMain">
            <div class="title mb20"><img :src="title" alt="礼遇"></div>
            <div class="taskWrap">
              <div class="item" v-for="(item, index) in taskList">
                <div class="task">
                  <div class="name"><span>{{ item.giftTip }}</span></div>
                  <div class="nameDes">{{ item.giftName }}</div>
                </div>
                <div class="giftWrap">
                  <div class="pic"><img :src="icon" alt=""></div>
                  <div class="giftItem" v-for="(item2, index2) in item.itemList"
                    :class="{ active: item.activeInd == index2 }" @click="choseGoods(index, index2)">
                    <div class="giftImg"><img :src="item2.itemImg" alt=""></div>
                    <div class="giftName" :style="{ color: item2.color }">{{ item2.name }}</div>
                  </div>
                </div>
                <div class="btnWrap">
                  <div class="process">进度 {{ item.buyCount }}/{{ item.totalCount }}</div>
                  <div class="btnBar">
                    <span class="btn" v-if="item.isClick == 1" @click="receive(item)">{{ item.btnText }}</span>
                    <span class="btn" v-else-if="item.isClick == 0 && item.btnUrl != ''" @click="goShop(item)">{{
                      item.btnText }}</span>
                    <span class="btn btn1" v-else>{{ item.btnText }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="rulesWrap">
              <div class="tit">规则说明：</div>
              <div class="cons">
                <p>1）每种礼遇奖励仅限一次。</p>
                <p>2）礼遇奖励选定后不可退换更改。</p>
                <p>3）请在活动礼包前下架前领取，逾期不补。</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import menuWX from '@/components/Menu'
import downItemWX from '@/views/downLoad/item'
import axios from "axios";
import Cookies from "js-cookie";
import { publicUrl } from '@/common/publicUrl.js'
export default {
  name: 'ceremony',
  components: {
    menuWX,
    downItemWX,
  },
  data() {
    return {
      loadShow: false,
      ceremonyBg: require('@/assets/imgs/ceremony/ceremonyBg.png'),
      title: require('@/assets/imgs/ceremony/title.png'),
      icon: require('@/assets/imgs/ceremony/icon.png'),
      taskList: [],
    }
  },
  created() {
    this.getGiftList()        // 获取礼遇列表
  },
  mounted() {
    document.documentElement.scrollTop = 0
  },
  methods: {
    getGiftList() {
      this.loadShow = true
      axios({
        method: 'post',
        url: '/Gift/getGiftList',
        headers: {
          token: Cookies.get('wx_token'),
        },
        data: {
          platForm: 'web',
          userId: Cookies.get('wx_userId'),      // userId
        },
      }).then((res) => {
        this.loadShow = false
        if (res.data.state == 200) {
          this.taskList = res.data.data.giftGoodsList.map(item => {
            item.activeInd = -1
            item.itemId = ''
            return item
          })
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        this.$message.error(err.message)
      })
    },      // 获取礼遇列表
    choseGoods(index, index2) {
      this.taskList[index].itemId = this.taskList[index].itemList[index2].itemId
      this.taskList[index].activeInd = index2
      console.log(this.taskList);
    },   // 切换选中的商品
    receive(item) {
      if (item.itemId == '') {
        this.$message.error('请选择奖励')
      } else {
        axios({
          method: 'post',
          url: '/Gift/getGiftPrize',
          headers: {
            token: Cookies.get('wx_token'),
          },
          data: {
            goodId: item.giftId,
            itemId: item.itemId,
            platForm: 'web',
            userId: Cookies.get('wx_userId'),      // userId
          },
        }).then((res) => {
          if (res.data.state == 200) {
            this.$message.success(res.data.message)
            this.getGiftList()
            this.getUserInfo()
          } else {
            this.$message.error(res.data.message)
          }
        }).catch(err => {
          this.$message.error(err.message)
        })
      }
      return false
    },      // 领取
    goShop(item) {
      if (item.btnUrl.indexOf('http') != -1) {
        window.open(item.btnUrl, '_blank')
      } else {
        this.$router.push({
          path: item.btnUrl,
        })
      }
    },           // 去购买 - 进入商城盲盒页面
    getUserInfo() {// 获取用户信息
      axios({
        method: 'post',
        url: '/user/getMemberInfo',
        headers: {
          token: Cookies.get('wx_token'),
        },
        data: {
          platForm: 'web',
          userId: Cookies.get('wx_userId')
        },
      }).then(res => {
        if (res.data.state == 200) {
          if (res.data && res.data.data) {
            this.userInfo = res.data.data
            this.resetSetItem('userInfo', JSON.stringify(res.data.data))
          }
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
.ceremonyWrap {
  .ceremonyBar {
    padding: 40px 40px 50px 40px;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .ceremonyMain {
      .title {
        text-align: center;
      }

      .taskWrap {
        padding-bottom: 10px;
        min-height: 500px;
        box-sizing: border-box;

        .item {
          margin-bottom: 10px;
          height: 100px;
          background: rgba(255, 255, 255, 0.5);
          box-shadow: 0 4px 10px 0 rgba(135, 135, 135, 0.25);
          border-radius: 0;
          display: flex;

          .task {
            width: 210px;
            min-width: 210px;
            padding-right: 20px;
            box-sizing: border-box;

            .name {
              margin-bottom: 30px;

              span {
                display: inline-block;
                padding: 0 40px 0 20px;
                height: 27px;
                line-height: 27px;
                color: #ffffff;
                background: #D22190;
                font-size: 14px;
              }
            }

            .nameDes {
              padding-left: 20px;
              font-size: 14px;
              color: #D22190;
            }
          }

          .giftWrap {
            display: flex;
            width: 500px;

            .pic {
              margin-right: 20px;
              width: 95px;
              height: 95px;
              box-sizing: border-box;
              position: relative;
              z-index: 1;

              img {
                width: 95px;
                height: 95px;
              }
            }

            .giftItem {
              padding-top: 10px;
              margin-right: 5px;
              width: 140px;
              text-align: center;
              cursor: pointer;

              .giftImg {
                margin-bottom: 3px;

                img {
                  width: 50px;
                  height: 50px;
                  border: 1px solid transparent;
                }
              }

              .giftName {
                font-size: 12px;
                color: #D22190;
              }
            }

            .active {
              .giftImg {
                img {
                  border: 1px solid #D22190;
                }
              }
            }
          }

          .btnWrap {
            margin-left: 30px;
            padding: 20px 30px 20px 0;
            width: 120px;
            box-sizing: border-box;
            text-align: center;

            .process {
              margin-bottom: 8px;
              font-size: 14px;
              color: #D22190;
            }

            .btn {
              display: inline-block;
              font-size: 20px;
              width: 96px;
              height: 42px;
              line-height: 42px;
              background: #D22190;
              color: #ffffff;
              border-radius: 4px;
              cursor: pointer;
            }

            .btn1 {
              background: #808080;
              cursor: default;
            }
          }
        }
      }

      .rulesWrap {
        padding: 20px;
        background: rgba(255, 255, 255, 0.5);
        box-shadow: 0 4px 10px 0 rgba(135, 135, 135, 0.25);
        border-radius: 4px;
        border: 1px solid #D22190;

        .tit {
          margin-bottom: 10px;
          font-size: 16px;
          font-weight: 700;
        }

        .cons {
          line-height: 30px;

          p {
            font-size: 16px;
          }
        }
      }
    }
  }
}
</style>
